<template>
  <div class="bookshelfcontent-container">
    <div class="bookshelfcontent-header">
      <div class="bookshelfcontent-header-title">
        {{ bookshelf?.name }}
      </div>
    </div>
    <div v-if="!content.length" class="bookshelfcontent-empty">{{ capitalize($t('bookshelf.emptyContent')) }}</div>
    <div v-else class="bookshelfcontent-list">
      <bookshelf-item
        v-for="item of content"
        :item="item"
        :selected="item.id === selectedPublication"
        :author="userIsAuthor"
        @selectQuery="onSelectQuery"
        @selectPublication="onSelectPublication"
        @deleteItem="onDeleteItem"
        :key="item.id"
        />
    </div>
    <Transition name="paneltransition">
      <module-result-detail
        v-show="detailPanelOpen"
        ref="detailLoader"
        @close="closeDetailPanel"
      ></module-result-detail>
    </Transition>
    <div v-if="state === STATES.LOADING" class="bookshelfcontent-loading">
      <spinner :size="60" :width="6"></spinner>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/utilities/Spinner'
import BookshelfItem from '@/components/bookshelf/BookshelfItem'
import ModuleResultDetail from '@/components/module-results/ModuleResultDetail'
import STATES from '@/constants/states'

export default {
  name: 'bookshelfcontent',
  data: () => ({
    selectedPublication: null,
    detailPanelOpen: false,
    state: STATES.READY,
    STATES
  }),
  computed: {
    bookshelfId () {
      return parseInt(this.$route.params.id)
    },
    bookshelf () {
      const userContent = this.$store.getters['bookshelfStore/getUserBookshelf'](this.bookshelfId)
      if (userContent) return userContent

      const externalContent = this.$store.getters['bookshelfStore/getExternalBookshelf']
      if (externalContent) return externalContent

      return null
    },
    content () {
      return this.bookshelf?.items ?? []
    },
    userIsAuthor () {
      const authorId = parseInt(this.bookshelf?.authorId)
      const userId = parseInt(this.$store.getters.getSession?.user?.id)

      if (isNaN(authorId) || isNaN(userId)) return false

      const userHasUserRole = this.$store.getters.userHasUserRole

      return (authorId === userId && userHasUserRole)
    }
  },
  methods: {
    onSelectQuery (data) {
      this.closeDetailPanel()
      this.$router.push({
        name: 'results',
        query: {
          query: JSON.stringify(data)
        }
      })
    },
    onSelectPublication (data) {
      this.selectedPublication = data.itemId

      const publicationInfo = { publication: data.publicationId, module: '' + data.connectionId }
      this.openDetailPanel(publicationInfo)
    },
    async onDeleteItem (itemId) {
      const bookshelfItemData = {
        bookshelfId: this.bookshelf.id,
        bookshelfItemId: itemId
      }

      this.state = this.STATES.LOADING

      const deleteResult = await this.$store.dispatch('bookshelfStore/deleteBookshelfItem', bookshelfItemData)
      if (deleteResult.error) {
        this.state = this.STATES.READY
        return
      }

      await this.$store.dispatch('bookshelfStore/fetchUserBookshelves')

      this.state = this.STATES.READY
    },
    closeDetailPanel () {
      this.detailPanelOpen = false
      this.selectedPublication = null
    },
    openDetailPanel (publicationInfo) {
      if (!this.$refs.detailLoader?.loadPublication) return

      this.$refs.detailLoader.loadPublication(publicationInfo)
      this.detailPanelOpen = true
    }
  },
  beforeRouteUpdate () {
    this.closeDetailPanel()
  },
  components: {
    spinner: Spinner,
    'bookshelf-item': BookshelfItem,
    'module-result-detail': ModuleResultDetail
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/css/_constants";

  .bookshelfcontent {
    &-empty {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
    }

    &-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }

    &-header {
      padding: 20px;
      border-bottom: 1px solid $grey_middle;

      &-title {
        font-weight: bold;
        font-size: ($font_size + 4);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-list {
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }

    &-loading {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 1;
    }
  }

  .paneltransition{
    &-enter-from, &-leave-to {
      right: -500px;
    }

    &-enter-active {
      transition: right 0.5s ease;
    }

    &-leave-active {
      transition: right 0.2s ease;
    }
  }
</style>
