<template>
  <div class="short">
    <div class="short-cover">
      <div v-if="hasShopAction && isNew" :title="$t('item.new.tooltip', { daysMarkedAsNew })" class="item-new"></div>
      <publication-cover :url="publication.cover" ref="cover"></publication-cover>
    </div>
    <div class="short-metadata">
      <div v-if="publication.authors?.length" class="short-metadata-field metadata-authors">{{ publication.authors.join('; ') }}</div>
      <div v-if="publication.title" class="short-metadata-field metadata-title">{{ publication.title }}</div>
      <div v-if="publication.subtitle" class="short-metadata-field metadata-subtitle">{{ publication.subtitle }}</div>
      <div v-if="publishingDisplay" class="short-metadata-field metadata-publication">{{ publishingDisplay }}</div>
      <div v-if="hasShopAction && priceDisplay" class="result-price">
        <span>{{ priceDisplay }}</span>
        <span class="item-price-tax">{{ $t('common.excluding-tax') }}</span>
        <span v-if="hasLicense" :title="$t('shop.library.items.already-in-collection')" class="shop-library-license-available-container"><img src="/img/icons/checkmark_color.png" /></span>
      </div>
      <module-result-actions
        style="margin-top: 10px;"
        :publication="publication"
        :connectionId="connectionId"
        :hasReadAction="hasReadAction"
        :hasRequestAction="hasRequestAction"
        :hasShopAction="hasShopAction"
        :hasOpenInBibisAction="hasOpenInBibisAction"
        />
    </div>
  </div>
</template>

<script>
import moduleResultMixin from '@/mixins/moduleResult'
import { mapState } from 'vuex'
import ModuleResultActions from '@/components/module-results/ModuleResultActions.vue'
import PublicationCover from '@/components/publication/PublicationCover'

export default {
  name: 'module-aggregate-publication',
  props: ['publication', 'connectionId'],
  mixins: [moduleResultMixin],
  computed: {
    ...mapState({
      searchQuery: state => state.shop.library.search.query,
      books: state => state.shop.library.books.items,
      totalAmountOfBooks: state => state.shop.library.books.totalAmount,
      selectedBook: state => state.shop.library.books.selectedItem,
      isLoading: state => state.shop.library.books.isLoading,
      errorLoading: state => state.shop.library.books.errorLoading
    }),
    isLoadingFirstPage () {
      return !!(this.isLoading && !this.books.length)
    },
    isLoadingAdditionalPages () {
      return !!(this.isLoading && this.books.length)
    },
    catalogueItemElements () {
      return this.$refs.catalogueItems || []
    },
    publishingDisplay () {
      const fields = []

      if (this.publication.publisher) fields.push(this.publication.publisher)
      if (this.publication.yearofpublication) fields.push(this.publication.yearofpublication)
      if (this.publication.edition) fields.push(`(${this.publication.edition})`)

      return fields.join(' ')
    }
  },
  components: {
    'publication-cover': PublicationCover,
    'module-result-actions': ModuleResultActions
  },
  mounted () {
    if (this.$refs.cover?.loadImage) this.$refs.cover.loadImage()
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .short {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    padding: 10px;
    cursor: pointer;

    &-cover {
      width: 100px;
      height: 150px;
    }

    &-metadata {
      padding-left: 20px;
      width: 600px;

      &-field {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .metadata {
    &-authors {
      font-style: italic;
      margin-bottom: 4px;
    }

    &-title {
      font-size: $font_size + 2;
      font-weight: bold;
    }

    &-publication {
      margin-top: 10px;
    }

    &-actions {
      float: left;
      margin-top: 10px;
      width: fit-content;
    }
  }
  .item-new {
    position: absolute;
    top: -8px;
    left: -9px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #e65133;
    border: 4px solid white;
    z-index: 1;
  }
  .result-price {
    margin-top: 10px;
    font-weight: bold;
    font-size: ($font_size + 4);
    width: fit-content;
  }
  .item-price-tax {
    margin-left: map-get($spacers, 1);
    font-size: 1rem;
    font-weight: normal;
  }
</style>
