<template>
  <div class="result" :class="{ 'result-tile': mode === 'tiles' }">
    <div class="result-panel-left">
      <div v-if="hasShopAction && isNew" :title="$t('item.new.tooltip', { daysMarkedAsNew })" class="item-new"></div>
      <input v-if="showCheckboxes" type="checkbox" class="result-checkbox" :value="publication.id" v-model="checkValue" @click.stop>
      <div class="result-cover-container">
        <publication-cover :url="publication.cover" ref="coverImg"></publication-cover>
      </div>
    </div>
    <div class="result-panel-right">
      <div v-if="publication.authors?.length" class="result-authors" :title="publication.authors.join('; ')">
        {{ publication.authors.join('; ') }}
      </div>
      <div v-if="publication.title" class="result-title">
        {{ publication.title }}
      </div>
      <div v-if="publication.subtitle" class="result-subtitle">
        {{ publication.subtitle }}
      </div>
      <div v-if="publication.documenttype" class="result-documenttype">
        <img v-if="publication.documenttypeicon" class="result-documenttypeicon" :src="publication.documenttypeicon" />
        {{ publication.documenttype }}
      </div>
      <div v-if="publication.abstract" class="result-abstract" v-html="publication.abstract"></div>
      <div v-if="hasShopAction && priceDisplay" class="result-price">
        <span>{{ priceDisplay }}</span>
        <span class="item-price-tax">{{ $t('common.excluding-tax') }}</span>
        <span v-if="hasLicense" :title="$t('shop.library.items.already-in-collection')" class="shop-library-license-available-container"><img src="/img/icons/checkmark_color.png" /></span>
      </div>
      <div v-if="publishingDisplay?.length" class="result-publishing">
        {{ publishingDisplay }}
      </div>
      <div v-if="publication.keywords?.length" class="result-keywords">
        {{ publication.keywords.join(', ') }}
      </div>
      <div v-if="hasMultimedia" class="result-multimedia">
        <div v-if="publication.multimedia.links?.length" class="result-multimedia-links">
          <publication-multimedia
            v-for="link of publication.multimedia.links"
            :key="link.url"
            :mediatype="MultimediaTypes.Link"
            :url="link.url"
            :display="link.label ? link.label : link.url"
          />
        </div>
        <div v-if="publication.multimedia.documents?.length" class="result-multimedia-documents">
          <publication-multimedia
            v-for="document of publication.multimedia.documents"
            :key="document"
            :mediatype="MultimediaTypes.Document"
            :url="document"
          />
        </div>
        <div v-if="publication.multimedia.sounds?.length" class="result-multimedia-sounds">
          <publication-multimedia
            v-for="sound of publication.multimedia.sounds"
            :key="sound"
            :mediatype="MultimediaTypes.Sound"
            :url="sound"
          />
        </div>
        <div v-if="publication.multimedia.movies?.length" class="result-multimedia-movies">
          <publication-multimedia
            v-for="movie of publication.multimedia.movies"
            :key="movie"
            :mediatype="MultimediaTypes.Movie"
            :url="movie"
          />
        </div>
        <div v-if="publication.multimedia.images?.length" class="result-multimedia-images">
          <publication-multimedia
            :mediatype="MultimediaTypes.ImageGallery"
            :images="publication.multimedia.images"
          />
        </div>
        <div v-if="publication.multimedia.copyDocuments?.length && clientSettings.showCopyDocuments" class="result-multimedia-copydocuments">
          <publication-multimedia
            v-for="copyDocument of publication.multimedia.copyDocuments.slice(0, maxVisibleCopyDocuments)"
            :key="copyDocument"
            :mediatype="MultimediaTypes.Document"
            :url="copyDocument"
          />
          <div v-if="publication.multimedia.copyDocuments.length > maxVisibleCopyDocuments">
            + {{ publication.multimedia.copyDocuments.length - maxVisibleCopyDocuments }} {{$t('moreCopyDocuments')}}
          </div>
        </div>
      </div>
      <module-result-actions
        class="result-actions"
        :publication="publication"
        :connectionId="connectionId"
        :hasReadAction="hasReadAction"
        :hasRequestAction="hasRequestAction"
        :hasShopAction="hasShopAction"
        :hasOpenInBibisAction="hasOpenInBibisAction"
        />
    </div>
  </div>
</template>

<script>
import moduleResultMixin from '@/mixins/moduleResult'
import { mapState } from 'vuex'
import PublicationCover from '@/components/publication/PublicationCover'
import ModuleResultActions from '@/components/module-results/ModuleResultActions.vue'
import PublicationMultimedia, { MultimediaTypes } from '@/components/publication/PublicationMultimedia'

export default {
  name: 'module-result',
  props: {
    publication: Object,
    connectionId: String,
    mode: String,
    showCheckboxes: {
      type: Boolean,
      default: () => false
    },
    check: Array
  },
  emits: ['update:check'],
  mixins: [moduleResultMixin],
  computed: {
    ...mapState({
      searchQuery: state => state.shop.library.search.query,
      books: state => state.shop.library.books.items,
      totalAmountOfBooks: state => state.shop.library.books.totalAmount,
      selectedBook: state => state.shop.library.books.selectedItem,
      isLoading: state => state.shop.library.books.isLoading,
      errorLoading: state => state.shop.library.books.errorLoading
    }),
    isLoadingFirstPage () {
      return !!(this.isLoading && !this.books.length)
    },
    isLoadingAdditionalPages () {
      return !!(this.isLoading && this.books.length)
    },
    catalogueItemElements () {
      return this.$refs.catalogueItems || []
    },
    publishingDisplay () {
      const fields = []

      if (this.publication.publisher) fields.push(this.publication.publisher)
      if (this.publication.yearofpublication) fields.push(this.publication.yearofpublication)
      if (this.publication.edition) fields.push(`(${this.publication.edition})`)
      return fields.join(' ')
    },
    hasMultimedia () {
      if (!this.publication.multimedia) return false

      return (
        this.publication.multimedia.documents?.length ||
        this.publication.multimedia.images?.length ||
        this.publication.multimedia.sounds?.length ||
        this.publication.multimedia.movies?.length ||
        this.publication.multimedia.links?.length ||
        (this.publication.multimedia.copyDocuments?.length && this.clientSettings.showCopyDocuments)
      )
    },
    clientSettings () {
      return this.$store.getters['settings/getClientSettings']
    },
    checkValue: {
      get () {
        return this.check
      },
      set (value) {
        this.$emit('update:check', value)
      }
    }
  },
  data: () => ({
    MultimediaTypes,
    maxVisibleCopyDocuments: 5
  }),
  components: {
    'publication-cover': PublicationCover,
    'publication-multimedia': PublicationMultimedia,
    'module-result-actions': ModuleResultActions
  },
  mounted () {
    if (this.$refs.coverImg) this.$refs.coverImg.loadImage()
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .result {
    display: flex;
    flex-direction: row;
    padding: 20px;

    .result-panel-left {
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      flex-shrink: 0;
      width: 102px;
    }

    .result-panel-right {
      width: 100%;
      padding-left: 20px;
      overflow: hidden;
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }
  }

  .result-authors {
    font-style: italic;
    margin-bottom: 4px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .result-title {
    font-weight: bold;
    font-size: ($font_size + 4);
  }

  .result-documenttype {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
    font-size: ($font_size - 2);

    .result-documenttypeicon {
      max-height: 20px;
      margin-right: 4px;
    }
  }

  .result-abstract {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4.2em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 20px;
  }

  .result-price {
    margin-top: 20px;
    font-weight: bold;
    font-size: ($font_size + 4);
  }

  .result-publishing {
    margin-top: 20px;
  }

  .result-keywords {
    margin-top: 4px;
    font-style: italic;
    font-size: ($font_size - 2);
  }

  .result-multimedia {
    margin-top: 20px;

    [class^="result-multimedia-"] + .result-multimedia-copydocuments::before {
      content: "";
      border-top: 1px solid #ccc;
      display: block;
      width: 300px;
      margin: 6px 0;
    }
  }

  .result-cover-container {
    width: 100px;
    height: 150px;
    margin-bottom: 10px;
  }

  .result.result-tile {
    .result-multimedia {
      display: none;
    }
  }

  .result-checkbox {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }

  .result-actions {
    margin-top: 20px;
  }

  .item-price-tax {
    margin-left: map-get($spacers, 1);
    font-size: 1rem;
    font-weight: normal;
  }
  .item-new {
    position: absolute;
    top: -8px;
    left: -9px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #e65133;
    border: 4px solid white;
    z-index: 1;
  }
</style>
