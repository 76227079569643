<template>
  <div class="pubmeta">

    <div class="section-top">
      <div class="top-cover">
        <div v-if="hasShopAction && isNew" :title="$t('item.new.tooltip', { daysMarkedAsNew })" class="item-new"></div>
        <publication-cover :url="publication.cover" ref="coverImg"></publication-cover>
      </div>
      <div class="top-data">
        <div v-if="publication.title" class="metadata-title">{{ publication.title }}</div>
        <div v-if="publication.subtitle" class="metadata-subtitle">{{ publication.subtitle }}</div>
        <div v-if="hasShopAction && priceDisplay" class="result-price">
          <span>{{ priceDisplay }}</span>
          <span class="item-price-tax">{{ $t('common.excluding-tax') }}</span>
          <span v-if="hasLicense" :title="$t('shop.library.items.already-in-collection')" class="shop-library-license-available-container"><img src="/img/icons/checkmark_color.png" /></span>
        </div>
      </div>
    </div>

    <module-result-actions
      style="margin-bottom: 20px;"
      :publication="publication"
      :connectionId="connectionId"
      :hasReadAction="hasReadAction"
      :hasRequestAction="hasRequestAction"
      :hasShopAction="hasShopAction"
      :hasOpenInBibisAction="hasOpenInBibisAction"
      />

    <div v-if="hasCharacteristics" class="section">
      <div class="section-header"><span>{{ capitalize($t('characteristics')) }}</span></div>
      <div v-if="publication.documenttype" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.documenttype')) }}</div>
        <div class="section-row-data metadata-documenttype">
          <img v-if="publication.documenttypeicon" class="result-documenttypeicon" :src="publication.documenttypeicon" />
          <searchable :value="publication.documenttype" :field="'documenttype'" />
        </div>
      </div>
      <div v-if="publication.authors?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.author')) }}</div>
        <div class="section-row-data">
          <div v-for="(author, index) of publication.authors" :key="author">
            <searchable :value="author" :field="'authors'" />{{ (index === publication.authors.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="publication.corporateauthors?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.corporateauthor')) }}</div>
        <div class="section-row-data">
          <div v-for="(corporateAuthor, index) of publication.corporateauthors" :key="corporateAuthor">
            <searchable :value="corporateAuthor" :field="'corporateauthors'" />{{ (index === publication.corporateauthors.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="publication.isbn" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.isbn')) }}</div>
        <div class="section-row-data">{{ publication.isbn }}</div>
      </div>
      <div v-if="publication.seriestitle" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.seriestitle')) }}</div>
        <div class="section-row-data"><searchable :value="publication.seriestitle" :field="'seriestitle'" /></div>
      </div>
      <div v-if="publication.seriesnumber" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.seriesnumber')) }}</div>
        <div class="section-row-data">{{ publication.seriesnumber }}</div>
      </div>
      <div v-if="publication.bibiscode" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.bibiscode')) }}</div>
        <div class="section-row-data">{{ publication.bibiscode }}</div>
      </div>
      <div v-if="publication.reference" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.reference')) }}</div>
        <div class="section-row-data" v-html="publication.reference"></div>
      </div>
      <div v-if="publication.signature" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.signature')) }}</div>
        <div class="section-row-data" v-html="publication.signature"></div>
      </div>
    </div>

    <div v-if="hasAbstract" class="section">
      <div class="section-header"><span>{{ capitalize($t('abstract')) }}</span></div>
      <div
        class="metadata-abstract"
        :class="{
          expandable: expandableAbstract,
          expanded: expandableAbstract && abstractExpanded,
          collapsed: expandableAbstract && !abstractExpanded
        }"
        v-html="publication.abstract"
        ref="abstractContent"
      ></div>
      <div v-if="expandableAbstract" class="abstract-toggle" @click="toggleAbstract">
        <div class="abstract-toggle-indicator" :class="[abstractExpanded ? 'up' : 'down']"></div>
      </div>
    </div>

    <div v-if="hasMultimedia" class="section">
      <div class="section-header"><span>{{ capitalize($t('multimedia')) }}</span></div>
      <div v-if="publication.multimedia.links?.length" class="metadata-multimedia-links">
        <publication-multimedia
          v-for="link of publication.multimedia.links"
          :key="link.url"
          :mediatype="MultimediaTypes.Link"
          :url="link.url"
          :display="link.label ? link.label : link.url"
        />
      </div>
      <div v-if="publication.multimedia.documents?.length" class="metadata-multimedia-documents">
        <publication-multimedia
          v-for="document of publication.multimedia.documents"
          :key="document"
          :mediatype="MultimediaTypes.Document"
          :url="document"
        />
      </div>
      <div v-if="publication.multimedia.sounds?.length" class="metadata-multimedia-sounds">
        <publication-multimedia
          v-for="sound of publication.multimedia.sounds"
          :key="sound"
          :mediatype="MultimediaTypes.Sound"
          :url="sound"
        />
      </div>
      <div v-if="publication.multimedia.movies?.length" class="metadata-multimedia-movies">
        <publication-multimedia
          v-for="movie of publication.multimedia.movies"
          :key="movie"
          :mediatype="MultimediaTypes.Movie"
          :url="movie"
        />
      </div>
      <div v-if="publication.multimedia.images?.length" class="metadata-multimedia-images">
        <publication-multimedia
          :mediatype="MultimediaTypes.ImageGallery"
          :images="publication.multimedia.images"
        />
      </div>
    </div>

    <div v-if="hasPublication" class="section">
      <div class="section-header"><span>{{ capitalize($t('publication')) }}</span></div>
      <div v-if="publication.publisher" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.publisher')) }}</div>
        <div class="section-row-data"><searchable :value="publication.publisher" :field="'publisher'" /></div>
      </div>
      <div v-if="publication.placeofpublication" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.placeofpublication')) }}</div>
        <div class="section-row-data">{{ publication.placeofpublication }}</div>
      </div>
      <div v-if="publication.yearofpublication" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.yearofpublication')) }}</div>
        <div class="section-row-data">{{ publication.yearofpublication }}</div>
      </div>
      <div v-if="publication.edition" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.edition')) }}</div>
        <div class="section-row-data">{{ publication.edition }}</div>
      </div>
      <div v-if="publication.annotation?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.annotation')) }}</div>
        <div class="section-row-data">
          <div v-for="value in publication.annotation" :key="value" class="metadata-repeatable">{{ value }}</div>
        </div>
      </div>
      <div v-if="publication.language" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.language')) }}</div>
        <div class="section-row-data">{{ publication.language }}</div>
      </div>
    </div>

    <div v-if="hasArchive" class="section">
      <div class="section-header"><span>{{ capitalize($t('archive')) }}</span></div>
      <div v-if="publication.filenumber" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filenumber')) }}</div>
        <div class="section-row-data">{{ publication.filenumber }}</div>
      </div>
      <div v-if="publication.filedatestart" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filedatestart')) }}</div>
        <div class="section-row-data">{{ publication.filedatestart }}</div>
      </div>
      <div v-if="publication.filedateend" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filedateend')) }}</div>
        <div class="section-row-data">{{ publication.filedateend }}</div>
      </div>
      <div v-if="publication.filedatedestroyed" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.filedatedestroyed')) }}</div>
        <div class="section-row-data">{{ publication.filedatedestroyed }}</div>
      </div>
    </div>

    <div v-if="hasOtherCharacteristics" class="section">
      <div class="section-header"><span>{{ capitalize($t('other characteristics')) }}</span></div>
      <div v-if="publication.source" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.source')) }}</div>
        <div class="section-row-data"><searchable :value="publication.source" :field="'source'" /></div>
      </div>
      <div v-if="publication.class?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.class')) }}</div>
        <div class="section-row-data">
          <div v-for="(classValue, index) of publication.class" :key="classValue">
            <searchable :value="classValue" :field="'class'" />{{ (index === publication.class.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="publication.classification" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.classification')) }}</div>
        <div class="section-row-data">{{ publication.classification }}</div>
      </div>
      <div v-if="publication.keywords?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.keywords')) }}</div>
        <div class="section-row-data">
          <div v-for="(keyword, index) of publication.keywords" :key="keyword">
            <searchable :value="keyword" :field="'keywords'" />{{ (index === publication.keywords.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="publication.geographicalkeywords?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.geographicalkeywords')) }}</div>
        <div class="section-row-data">
          <div v-for="(keyword, index) of publication.geographicalkeywords" :key="keyword">{{ keyword }}{{ (index === publication.geographicalkeywords.length - 1) ? '' : ';' }}</div>
        </div>
      </div>
      <div v-if="publication.nuance?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.nuance')) }}</div>
        <div class="section-row-data">
          <div v-for="(nuance, index) of publication.nuance" :key="nuance">
            <searchable :value="nuance" :field="'nuance'" />{{ (index === publication.nuance.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
      <div v-if="publication.extranuance?.length" class="section-row">
        <div class="section-row-label">{{ capitalize($t('metadataField.extranuance')) }}</div>
        <div class="section-row-data">
          <div v-for="(extranuance, index) of publication.extranuance" :key="extranuance">
            <searchable :value="extranuance" :field="'extranuance'" />{{ (index === publication.extranuance.length - 1) ? '' : ';' }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasTitleRelations" class="section">
      <div class="section-header"><span>{{ capitalize($t('title relations')) }}</span></div>
      <div v-if="publication.titlerelations.parents?.length">
        <h4>{{ capitalize($t('metadataField.parenttitles')) }}</h4>
        <div v-for="parent of publication.titlerelations.parents" :key="parent.code" class="metadata-titlerelation">
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.title')) }}</div>
            <div class="section-row-data">
                {{ parent.title }}
            </div>
          </div>
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.bibiscode')) }}</div>
            <div class="section-row-data">
              <searchable :value="parent.code" :field="'bibiscode'" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="publication.titlerelations.children?.length">
        <h4>{{ capitalize($t('metadataField.childtitles')) }}</h4>
        <div v-for="child of publication.titlerelations.children" :key="child.code" class="metadata-titlerelation">
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.title')) }}</div>
            <div class="section-row-data">
                {{ child.title }}
            </div>
          </div>
          <div class="section-row">
            <div class="section-row-label">{{ capitalize($t('metadataField.bibiscode')) }}</div>
            <div class="section-row-data">
              <searchable :value="child.code" :field="'bibiscode'" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasCopies" class="section section-copies">
      <div class="section-header"><span>{{ capitalize($t('copies')) }}</span></div>
      <publication-copy
        v-for="copy of publication.copies"
        :key="copy.year + copy.part + copy.subscription + copy.registry + copy.isbn"
        :copy="copy"
      ></publication-copy>
    </div>

  </div>
</template>

<script>
import moduleResultMixin from '@/mixins/moduleResult'
import { mapState, mapActions } from 'vuex'
import PublicationCopy from '@/components/publication/PublicationCopy'
import PublicationCover from '@/components/publication/PublicationCover'
import PublicationSearchable from '@/components/publication/PublicationSearchable'
import PublicationMultimedia, { MultimediaTypes } from '@/components/publication/PublicationMultimedia'
import ModuleResultActions from '@/components/module-results/ModuleResultActions.vue'

const ABSTRACT_VISIBLE_LIMIT = 200

export default {
  name: 'publication-metadata',
  props: {
    publication: {
      type: Object,
      default: () => ({})
    },
    connectionId: String
  },
  mixins: [moduleResultMixin],
  computed: {
    ...mapState({
      searchQuery: state => state.shop.library.search.query,
      books: state => state.shop.library.books.items,
      totalAmountOfBooks: state => state.shop.library.books.totalAmount,
      selectedBook: state => state.shop.library.books.selectedItem,
      isLoading: state => state.shop.library.books.isLoading,
      errorLoading: state => state.shop.library.books.errorLoading,
      booksInBasket: state => state.shop.basket.books
    }),
    isLoadingFirstPage () {
      return !!(this.isLoading && !this.books.length)
    },
    isLoadingAdditionalPages () {
      return !!(this.isLoading && this.books.length)
    },
    catalogueItemElements () {
      return this.$refs.catalogueItems || []
    },
    hasCharacteristics () {
      return (
        this.publication.documenttype ||
        this.publication.authors?.length ||
        this.publication.corporateauthors?.length ||
        this.publication.isbn ||
        this.publication.seriestitle ||
        this.publication.seriesnumber ||
        this.publication.bibiscode ||
        this.publication.reference ||
        this.publication.signature
      )
    },
    hasAbstract () {
      return this.publication.abstract
    },
    hasMultimedia () {
      if (!this.publication.multimedia) return false

      return (
        this.publication.multimedia.documents?.length ||
        this.publication.multimedia.images?.length ||
        this.publication.multimedia.sounds?.length ||
        this.publication.multimedia.movies?.length ||
        this.publication.multimedia.links?.length
      )
    },
    hasPublication () {
      return (
        this.publication.publisher ||
        this.publication.placeofpublication ||
        this.publication.yearofpublication ||
        this.publication.edition ||
        this.publication.annotation?.length ||
        this.publication.language
      )
    },
    hasArchive () {
      return (
        this.publication.filenumber ||
        this.publication.filedatestart ||
        this.publication.filedateend ||
        this.publication.filedatedestroyed
      )
    },
    hasOtherCharacteristics () {
      return (
        this.publication.source ||
        this.publication.class?.length ||
        this.publication.classification ||
        this.publication.keywords?.length ||
        this.publication.geographicalkeywords?.length ||
        this.publication.nuance?.length ||
        this.publication.extranuance?.length
      )
    },
    hasTitleRelations () {
      return this.publication.titlerelations && (
        this.publication.titlerelations.children?.length ||
        this.publication.titlerelations.parents?.length
      )
    },
    hasCopies () {
      return this.publication.copies?.length
    }
  },
  data: () => ({
    expandableAbstract: false,
    abstractExpanded: false,
    MultimediaTypes
  }),
  methods: {
    ...mapActions('shop/basket', [
      'ADD_BOOK_TO_BASKET'
    ]),
    handleDebouncedScrollEvent () {
      this.loadImagesForBooksInViewPort()
      this.$refs.listLazyLoader.onScroll()
    },
    toggleAbstract () {
      this.abstractExpanded = !this.abstractExpanded
    }
  },
  components: {
    'publication-copy': PublicationCopy,
    'publication-cover': PublicationCover,
    searchable: PublicationSearchable,
    'publication-multimedia': PublicationMultimedia,
    'module-result-actions': ModuleResultActions
  },
  mounted () {
    if (this.$refs.coverImg) this.$refs.coverImg.loadImage()

    if (this.$refs.abstractContent) {
      const abstractHeight = this.$refs.abstractContent.offsetHeight
      if (abstractHeight > ABSTRACT_VISIBLE_LIMIT) this.expandableAbstract = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/_constants";

  .pubmeta {
    width: 100%;
    height: 100%;
    padding: 20px;
  }

  .metadata-title {
    font-weight: bold;
    font-size: ($font_size + 4);
  }

  .result-price {
    margin-top: 20px;
    font-weight: bold;
    font-size: ($font_size + 4);
  }

  .metadata-documenttype {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      max-height: 20px;
      margin-right: 4px;
    }
  }

  .section-top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
  }

  .section-header {
    margin-bottom: 4px;

    span {
      font-weight: bold;
      font-size: ($font_size + 4);
      background-color: white;
      padding-right: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      border-bottom: 1px solid #ccc;
    }
  }

  .section-row {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    margin: 4px 0;

    .section-row-label {
      width: 140px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .section-row-data {
      width: 100%;
    }
  }

  .top-data {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .top-cover {
    width: 100px;
    height: 150px;
    margin-right: 20px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .metadata-repeatable {
    &:not(:first-child) {
      margin-top: 4px;
    }
  }

  .metadata-abstract {
    &.expandable {
      &.collapsed {
        height: 110px;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 60px;
          background: linear-gradient(rgba(255, 255, 255, 0), white);
        }
      }

      &.expanded {
        padding-bottom: 16px;
      }
    }
  }

  .abstract-toggle {
    width: 100%;
    height: 10px;
    border-top: 1px solid #ccc;
    cursor: pointer;

    .abstract-toggle-indicator {
      position: absolute;
      top: -10px;
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid $main_light;
      border-radius: 50%;

      &::after {
        content: "";
        position: absolute;
        top: calc(50% - 2px);
        left: calc(50% - 4px);
        border-top: 6px solid $main_light;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }

      &.up::after {
        top: calc(50% - 4px);
        border-top: none;
        border-bottom: 6px solid $main_light;
      }
    }
  }

  .section-copies .copy {
    margin-left: -10px;
    width: calc(100% + 20px);

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .metadata-titlerelation {
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    margin-left: -10px;
    width: calc(100% + 20px);

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .item-price-tax {
    margin-left: map-get($spacers, 1);
    font-size: 1rem;
    font-weight: normal;
  }
  .item-new {
    position: absolute;
    top: -8px;
    left: -9px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #e65133;
    border: 4px solid white;
    z-index: 1;
  }
</style>
